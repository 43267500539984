import React from 'react';
import * as emailjs from 'emailjs-com';
import { Button, FormGroup } from 'reactstrap';
import { Formik, Field, Form } from 'formik';
import { ReactstrapInput } from 'reactstrap-formik';

import 'bootstrap/dist/css/bootstrap.min.css';

import Swal from 'sweetalert2';

//import { isEmail } from "validator";

//import Recaptcha from 'react-recaptcha';

import * as Yup from 'yup';

const SignupSchema = Yup.object().shape({
    name: Yup.string().required('Name Required'),
    phone: Yup.string().required('Phone Required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Email Required')
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EndorsementForm = () => (
    <div>
        <Formik
            initialValues={{
                companyname: '',
                name: '',
                email: '',
                phone: '',
                comments: ''
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, { resetForm }) => {
                //await new Promise((r) => setTimeout(r, 500));
                //alert(JSON.stringify(values, null, 2));
                console.log(values);
                emailjs.sendForm('obrien_365', 'arborcontact', '#arborcontactform', 'user_vOc0ylPHeC2nCdyLQJAiW');
                resetForm({ values: '' });
                Swal.fire({
                    title: 'Your form has been submitted, Thanks for contacting us at Arbor Snowboards',
                    icon: 'success'
                });
            }}
        >
            {({ errors, touched }) => (
                <Form id='arborcontactform'>
                    <FormGroup className='padding'>
                        <Field type='text' id='companyname' name='companyname' label='Company Name' component={ReactstrapInput} />
                    </FormGroup>
                    <FormGroup className='padding'>
                        <Field type='text' id='name' name='name' label='Name' component={ReactstrapInput} />
                    </FormGroup>
                    <FormGroup className='padding'>
                        <Field id='email' label='Email' name='email' component={ReactstrapInput} />
                    </FormGroup>
                    <FormGroup className='padding'>
                        <Field type='tel' id='phone' label='Phone' name='phone' component={ReactstrapInput} />
                    </FormGroup>
                    <FormGroup className='padding'>
                        <Field type='textarea' id='comments' label='Comments' name='comments' component={ReactstrapInput} />
                    </FormGroup>

                    <div className='padding'>
                        <FormGroup className='padding'>
                            <Button variant='primary' type='submit'>
                                Submit
                            </Button>
                        </FormGroup>
                    </div>
                    <div className='padding'>
                        <div className='padding erroring'>
                            {errors.name && touched.name ? <div>{errors.name}</div> : null}
                            {errors.phone && touched.phone ? <div>{errors.phone}</div> : null}
                            {errors.email && touched.zip ? <div>{errors.email}</div> : null}
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    </div>
);

export default EndorsementForm;
