import React from "react";
// @ts-ignore
import Parse from 'parse';
// @ts-ignore
import {GeneralAssetUrl} from './../../themes/kent/utilities/constants';

interface IProps {
  product?:string;
  mailingListCta?:string;
  serialNumberLocation?:any;
  serialNumberLocationText?:any;
}

interface IState {
  name?:any;
  email?:any;
  date?:any;
  mailingList?:any;
  serialNumber?:any;
  emailError?:any;
  messageSent?:any;
}

class CostcoRegistrationForm extends React.Component<IProps> {
  public state: IState;

  constructor(props:any) {
    super(props);
      this.state = {
          name: '',
          email:'',
          date:'',
          serialNumber:'',
          mailingList:false
      };
  }
  handleNameChange = (e:any) =>{
    var name = e.target.value;
    this.setState({
      name:name
    })
  }
  handleDateChange = (e:any) =>{
    var date = e.target.value;
    this.setState({
      date:date
    })
  }
  handleEmailChange = (e:any) =>{
    var email = e.target.value;
    this.setState({
      email:email
    })
  }
  mailingListChange = (e:any) =>{
    console.log(e.target)
    var value = e.target.checked;
    this.setState({
      mailingList:value
    })
  }
  handleSerialNumberChange = (e:any) =>{
    var serialNumber = e.target.value;
    this.setState({
      serialNumber:serialNumber
    })
  }
  validateEmail = (e:any) =>{
    var email = e.target.value;
    if(email.length > 0 && email.indexOf('@') == -1){
      this.setState({
        emailError:true
      })
    } else{
      this.setState({
        emailError:false
      })
    }
  }

  submitForm = () => {
    if(!this.state.name || !this.state.email || !this.state.serialNumber){
      alert('Please complete all fields')
    }
    else if(this.state.name == "" || this.state.email == "" || this.state.serialNumber == ""){
      alert('Please complete all fields')
    }
    else if(this.state.name == " " || this.state.email == " " || this.state.serialNumber ==" " ){
      alert('Please complete all fields')
    }
    else{
      Parse.Cloud.run('costcoRegistrationForm',
      { name: this.state.name,
        date:this.state.date,
        email: this.state.email,
        serialNumber: this.state.serialNumber,
        product:this.props.product,
        mailingList:this.state.mailingList
      }).then((response:any) => {
        this.setState({
          messageSent:true,
          name: "",
          email: "",
          serialNumber: "",
        })
      });
    }
  }
  render() {
    let cta = this.props.mailingListCta ? this.props.mailingListCta : 'Get product & company updates';
    let image = this.props.serialNumberLocation ? this.props.serialNumberLocation.src : '';
    return (
      <div>
        <div className="small-padding light-grey-background costco-registration-form">
          <div className="costco-registration-form-wrapper shadow white-background">
            {!this.state.messageSent && <div>
              <div className="row">
                <div className="small-12 medium-5">
                  {image && image != '' &&
                    <div className="full-width background-image-fill" style={{height:'100%',backgroundImage:"url("+image+")"}}  />
                  }
                </div>
                <div className="small-12 medium-7 small-padding no-padding-top no-padding-bottom">
                  <p className="form-label subheading">{'Serial Number'}</p>
                  {this.props.serialNumberLocationText && <h6 className="form-sublabel subheading">{this.props.serialNumberLocationText}</h6>}
                  <input className="contact-form-input" type="text" name="name" value={this.state.serialNumber} onChange={this.handleSerialNumberChange} />
                  <p className="form-label subheading">{'Date of Purchase'}</p>
                  {this.props.serialNumberLocationText && <h6 className="form-sublabel subheading">{'Keep Your Receipt'}</h6>}
                  <input className="contact-form-input" type="text" name="date" value={this.state.date} onChange={this.handleDateChange}/>
                  <p className="form-label subheading">{'Name'}</p>
                  <input className="contact-form-input" type="text" name="name" value={this.state.name} onChange={this.handleNameChange}/>
                  <p className="form-label subheading">{'Email'}</p>
                  {this.state.emailError && <h6 className="red-color" style={{fontSize:'12px'}}>{'Email is not valid'}</h6>}
                  <input className="contact-form-input" style={{border:this.state.emailError ? '1px solid red' : '1px solid rgba(0, 0, 0, 0.1)'}} type="email" name="email" value={this.state.email} onChange={this.handleEmailChange} onBlur={this.validateEmail} />
                  <div className="row">
                    <input type="checkbox" name="name" checked={this.state.mailingList} onChange={this.mailingListChange} style={{height: 20, width: 20, marginTop: 5, marginRight: 10}}/>
                    <p className="form-label subheading">{cta}</p>
                  </div>
                  <div className="centered-text" style={{marginTop:"20px"}}>
                    <div className="button primary-color-background white full-width" onClick={this.submitForm}>{'Register Product'}</div>
                  </div>
                </div>
              </div>

            </div>}
            {this.state.messageSent &&
              <div className="success-container">
                <div className="success-container-text full-width  centered-text">
                  <img className="success-check" src="/images/checked.png" />
                  <h4 className="full-width dark-grey">{'Registration Completed'}</h4>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}
//@ts-ignore
export default CostcoRegistrationForm;
